import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Blurhash } from "react-blurhash";
import { hobbiesData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Hobby = () => {
  const [loadedImages, setLoadedImages] = useState({});
  const cardsRef = useRef([]);

  useIntersectionObserver(cardsRef);

  const handleImageLoad = (src) => {
    setLoadedImages((prev) => ({ ...prev, [src]: true }));
  };

  useEffect(() => {
    hobbiesData.forEach(({ image }) => {
      const img = new Image();
      img.src = image;
      img.onload = () => handleImageLoad(image);
    });
  }, []);

  return (
    <section id="hobbies" className="p-8">
      <div className="flex flex-col items-center justify-center">
        <h2
          ref={(el) => (cardsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-purple-400 w-full max-w-[1200px] zoom"
        >
          Hobby
        </h2>
        <Slider
          {...settings}
          className="slick-slider  w-full max-w-[1200px]"
          arrows
          prevArrow={<div className="slick-prev" />}
          nextArrow={<div className="slick-next" />}
        >
          {hobbiesData.map((hobby, index) => (
            <div key={index} className="relative outline-none">
              <div className="relative w-full h-72 overflow-hidden">
                {!loadedImages[hobby.image] && (
                  <Blurhash
                    hash="LGF5?xYk^6#M@-5c,1J5@[or[Q6."
                    width="100%"
                    height="100%"
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                )}
                <img
                  loading="lazy"
                  src={hobby.image}
                  alt={hobby.name}
                  className={`w-full h-72 object-cover rounded-lg transition-transform duration-300 ${
                    loadedImages[hobby.image] ? "block" : "hidden"
                  } hover:scale-105`}
                />
              </div>
              <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-60 text-white text-center p-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <h3 className="m-0 text-sm">{hobby.name}</h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Hobby;
