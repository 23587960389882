import React, { useRef } from "react";
import { awardsData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";
import "./styles.css"; // Make sure to import the CSS file for styling

const Award = () => {
  const cardsRef = useRef([]);
  useIntersectionObserver(cardsRef);

  return (
    <section id="awards" className="p-8 font-suse">
      <div className="flex flex-col items-center justify-center">
        <h2
          ref={(el) => (cardsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-purple-400 w-full max-w-[1200px] zoom"
        >
          Award
        </h2>
        <div className="flex flex-wrap justify-center gap-10 w-full max-w-[1200px] ">
          {awardsData.map((award, index) => (
            <div
              ref={(el) => (cardsRef.current[index + 1] = el)}
              className="w-[300px] h-[200px] m-4 perspective-1000 fade"
              key={index}
            >
              <div className="relative w-full h-full transition-transform duration-600 transform-style-3d">
                <div className="absolute w-full h-full flip-card-inner">
                  {/* Front Side */}
                  <div className="flip-card-front rounded-lg shadow-md flex flex-col justify-center items-center overflow-hidden p-4 box-border bg-gray-800">
                    <h3 className="font-bold text-white text-[1.2rem] m-0">
                      {award.title}
                    </h3>
                    <span className="text-[#dedede] text-[1rem] italic absolute top-4 right-4">
                      {award.year}
                    </span>
                    <span className="text-purple-400 font-bold text-[1rem] absolute bottom-4 left-4">
                      {award.sub}
                    </span>
                  </div>
                  {/* Back Side */}
                  <div className="flip-card-back flex justify-center items-center bg-gray-700 rounded-lg shadow-md">
                    {/* Content for back side */}
                    <p className="text-white text-lg p-4 text-center">
                      {award.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Award;
